export function persistToken(token) {
    // Use localStorage to persist the token
    console.log('persistToken', token)
    localStorage.setItem('sessionToken', token);
}

export function getToken() {
    // Get the token from localStorage
    const token = localStorage.getItem('sessionToken');
    console.log('getToken', token)
    return token;
}

// viewer | editor | admin
export function getUserRole() {

    const jwt = getToken();
    if (!jwt) {
        return ""
    }

    const payload = getJwtPayload(jwt);
    console.log("payload", payload);
    return payload['role'];
}

export function isAdmin(){
    return getUserRole() === "admin";
}

export function isEditor(){
    return getUserRole() === "editor";
}

export function isViewer(){
    return getUserRole() === "viewer";
}

export function isLoggedIn(){
    return getUserRole() != "";
}



export function getJwtPayload(token) {
    // Split the token into its three parts
    const base64Url = token.split('.')[1]; // Get the payload part
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Replace URL-safe characters
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload); // Convert the JSON string to an object
}