import { Lafz } from "./pages/Lafz";
import { Auth } from "./pages/Auth";
import { Home }  from "./pages/Home";
import { Menu } from "./pages/Menu";
import { Dashboard } from "./pages/Dashboard";
import { ErrorPage }  from "./pages/ErrorPage";
import { AddLafz } from "./pages/AddLafz";
import { GoogleOAuthProvider } from '@react-oauth/google';

import './styles/style.css';
import './styles/responsive.css';
import './styles/css/all.css';
import './styles/fonts.css';

import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
  } from "react-router-dom";
  
const router = createBrowserRouter([
{
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
},
{
    path: "login",
    element: <Auth />,
},
{
    path: "e/:id",
    element: <Lafz />,
},
{
    path: "menu",
    element: <Menu />,
},
{
    path: "dashboard",
    element: <Dashboard />,
},
{
    path: "addlafz",
    element: <AddLafz />,
},

]);

export default function App(){
    return (
        <>
            <GoogleOAuthProvider clientId="21287849907-4h80japnuresns7v563covpfb4fmftbg.apps.googleusercontent.com">
                <RouterProvider router={router} />
            </GoogleOAuthProvider>
        </>
    )
}