import {
    Link,
  } from "react-router-dom";

export function Header() {
    return(
        <header className="header">
            <Link className="link" to={`./Menu`}>
            <div className="menu">
                <i class="fa-solid fa-burger"></i>
            </div>
            </Link>
            <div className="logo">
                <img src="../logo/lafzhub.png" alt="" />
            </div>
        </header>
    )
}