import '../styles/style.css';
import '../styles/responsive.css';
import '../styles/css/all.css'

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import {useState} from 'react'
import { useEffect } from 'react';

import { isAdmin, isEditor } from '../utils/auth';
import {useNavigate} from 'react-router-dom';

import {
    Link,
  } from "react-router-dom";


export function Dashboard() {
    const navigate = useNavigate();

    if (!isAdmin() && !isEditor()){
        navigate('/');
    }

    const DashboardEditBtn = (props) => {
        return <button className='dashboard-edit-btn' onClick={() => window.alert('clicked')}><i class="fa-regular fa-pen-to-square"></i> ویرایش</button>
    };
    const DashboardDeleteBtn = (props) => {
        return <button className='dashboard-delete-btn' onClick={() => window.alert('clicked')}><i class="fa-solid fa-delete-left"></i> حذف</button>
    };

    const [rowData, setRowData] = useState([
        
      ]);
      
      const [colDefs, setColDefs] = useState([
        { field: "expression", filter:true, floatingFilter:true, flex:1 },
        { field: "ipa", filter:true, floatingFilter:true, flex:1 },
        { field: "description", filter:true, floatingFilter:true, flex:1 },
        { field: "id", filter:true, floatingFilter:true, flex:1 },
        { field: "edit expression", cellRenderer: DashboardEditBtn, flex:1 },
        { field: "delete expression", cellRenderer: DashboardDeleteBtn, flex:1 },
      ]);

      const [expressions, setExpression] = useState([])

      useEffect( () => {
            getExpressions()
            
      }, [])
      
      useEffect( () => {
        setRowData(expressions.map(e => ({
            id : e.id,
            expression : e.expression,
            description : e.description,
            ipa : e.ipa
        })))            
      }, [expressions])
  
      async function getExpressions(){
          const response = await fetch('https://lafzhub.com/api/expressions')
          const expressions = (await response.json()).data
          setExpression(expressions)
          console.log(expressions)
      }
    return(
        <div>
            <section className="dashboard-panel">
                <aside className="dashboard-bar">
                    <div className="dashboard-header">
                        <h1><i class="fa-solid fa-gauge"></i> دشبورد</h1>
                    </div>
                    <div className="dashboard">
                        <Link className="link" to={`/Dashboard`}>
                        <div className="dashboard-tab">
                            <a href="#"><i class="fa-solid fa-list"></i> لفظ ها</a>
                        </div>
                        </Link>
                        <div className="dashboard-tab">
                            <a href="#"><i class="fa-solid fa-eye-low-vision"></i> دسترسی ها</a>
                        </div>
                    </div>
                </aside>
                <main className="main-panel">
                    <div className="main-header">
                        <h3><i class="fa-solid fa-hand-peace"></i> ادمین جون خوش اومدی</h3>
                    </div>
                    <div className='add-lafz-btn'>
                    <Link className="link" to={`/AddLafz`}>
                        <button><i class="fa-regular fa-square-plus"></i> لفظ جدید</button>
                    </Link>
                    </div>
                    <div className='lafz-table'>
                    <div
                        className="ag-theme-quartz-dark"
                        style={{ width: '100%',height:500}}
                        >
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={colDefs}
                            enableRtl={true}
                        />
                        </div>
                    </div>
                </main>
            </section>
        </div>
    )
}