import {getToken} from './auth.js'

const server = 'https://lafzhub.com/';

export function get(url){
    url = server + url;
    const token = getToken();
    // Set up the headers with the JWT token
    const headers = {
        'Content-Type': 'application/json'  // Optional: Set content type
    };
    if (token){
        headers['Authorization'] = `Bearer ${token}`;
    }

    // Make the GET request using fetch
    return fetch(url, {
        method: 'GET', // Optional, since GET is the default method
        headers: headers
    })
}

export function post(url, body){
    url = server + url;
    const token = getToken();
    // Set up the headers with the JWT token
    const headers = {
        'Content-Type': 'application/json'  // Optional: Set content type
    };
    if (token){
        headers['Authorization'] = `Bearer ${token}`;
    }

    // Make the GET request using fetch
    return fetch(url, {
        method: 'POST', // Optional, since GET is the default method
        headers: headers,
        body: JSON.stringify(body)
    })
}