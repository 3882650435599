import '../styles/style.css';
import '../styles/responsive.css';
import '../styles/css/all.css'

import {
    Link,
  } from "react-router-dom";
import { isAdmin, isEditor, isViewer } from '../utils/auth';

export function Menu() {
    return(
        <body className='menu-body'>
            <header className='menu-header'>
            <Link className="back-link" to={`/`}>
                <i class="fa-regular fa-circle-xmark"></i>
            </Link>
            </header>
            <div className="menu-page">
                <ul>
                    <Link className="link" to={`/Login`}>
                    <li>
                        <a href=""><i class="fa-solid fa-right-to-bracket"></i> ورود</a>
                    </li>
                    </Link>
                    <li>
                        <a href="#"><i class="fa-solid fa-address-card"></i> درباره ما</a>
                    </li>
                    <li>
                        <a href="https://zil.ink/amirrazor"><i class="fa-solid fa-phone-flip"></i> ارتباط</a>
                    </li>
                    {(isAdmin() || isEditor()) && showDashboardLink()}
                </ul>
            </div>
        </body>
    )
}

function showDashboardLink(){
    return <li>
        <a href="/dashboard"><i class="fa-solid fa-gauge"></i> دشبورد</a>
    </li>
}