import { MainHeader } from "../MainHeader";
import { Comment } from "../Comment";
import { get, post } from "../utils/request";
import { useLocation, useParams } from "react-router-dom";
import {useState, useEffect} from 'react'

import '../styles/style.css';
import '../styles/responsive.css';
import '../styles/css/all.css'

export function Lafz(props){
    let { id } = useParams();
    const [expression, setExpression] = useState({});

    useEffect( () => {
        getExpression(id)
    }, [])

    async function getExpression(id){
        const response = await get(`api/expressions/${id}`)
        const e = (await response.json())
        setExpression(e)
        console.log(expression)
    }

    async function like(){
        if(expression.liked){
            await post(`api/expressions/${id}/unlike`)
        }
        else {
            await post(`api/expressions/${id}/like`)
        }
        getExpression(id)
    }

    let tags = [];
    if (expression.tags){
        tags = expression.tags.trim() == "" ? [] : expression.tags.split(" ");
    }

    return(
        <main className="main">
            {expression != {} && <div className="main-div">
                <MainHeader />
                <div className="lafz-card">
                    <div className="lafz">
                        <div>
                        <i class="fa-solid fa-volume-high"></i>
                            <h1>{expression.expression}</h1>
                            <h4>/{expression.ipa}/</h4>
                        </div>
                    </div>
                    <div className="lafz-description">
                        <p>{expression.description}</p>
                    </div>
                    <div className="lafz-hashtag">
                    {
                        
                        tags.map( tag => (
                            <div>
                                <a href={`/tags/${tag}`}>#{tag}</a>
                            </div>
                        ))
                    }
                    </div>
                    <div className="lafz-icon">
                        <div>
                            <p>{props.comment} <i id="comment" class="fa-regular fa-comment"></i></p>
                        </div>
                        <div onClick={() => like()}>
                            <p>{expression.likes_count} <i class={`${expression.liked==true ? 'fa-solid ' : 'fa-regular'} fa-heart`}></i></p>
                        </div>
                    </div>
                </div>
                <Comment />
            </div>}
        </main>
    )
}